import * as Chakra from "@chakra-ui/react";
import * as React from "react";

const { chakra } = Chakra;

export const Table = (props: Chakra.TableProps) => (
  <chakra.div marginY="8">
    <Chakra.Table {...props} />
  </chakra.div>
);

export const Thead = Chakra.Thead;
export const Tbody = Chakra.Tbody;
export const Th = Chakra.Th;
export const Td = Chakra.Td;
