import { Heading, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import * as React from "react";

export const GuideLinkBox = (props: {
  title: string;
  desc: string;
  url: string;
}) => {
  const { title, desc, url } = props;
  return (
    <LinkBox
      as="article"
      p="5"
      borderWidth="1px"
      rounded="md"
      my="2"
      _hover={{ borderColor: "border-hover" }}
    >
      <Heading size="md" my="2">
        <LinkOverlay href={url}>{title}</LinkOverlay>
      </Heading>
      <Text mb="3">{desc}</Text>
    </LinkBox>
  );
};
