import { Box, useBoolean } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import theme from "prism-react-renderer/themes/nightOwl";
import React, { ReactElement, useEffect } from "react";

import { CodeContainer } from "./code-container";
import CopyButton from "./copy-button";
import Highlight from "./highlight";

const ReactLiveBlock = dynamic(() => import("./react-live-block"));

export type CodeBlockProps = {
  children: ReactElement;
};

export function CodeBlock(props: CodeBlockProps) {
  const [isMounted, { on }] = useBoolean();
  useEffect(
    /**
     * Lazily-load <ReactLiveBlock /> to save bundle size.
     */
    on,
    [on]
  );
  const {
    className,
    live = true,
    manual,
    render,
    children,
    viewlines,
    ln,
    mountStylesheet = false,
  } = props.children.props;

  const _live = live === "true" || live === true;

  const language = className?.replace(/language-/, "");
  const rawCode = children.trim();

  const reactLiveBlockProps = {
    rawCode,
    language,
    theme,
    noInline: manual,
    mountStylesheet,
  };

  // Editable live examples disabled for now

  // if (isMounted && language === "jsx" && _live === true) {
  //   return <ReactLiveBlock editable {...reactLiveBlockProps} />;
  // }

  return (
    <Box position="relative" zIndex="0">
      <CodeContainer px="0" overflow="hidden">
        <Highlight
          codeString={rawCode}
          language={language}
          theme={theme}
          metastring={ln}
          showLines={viewlines}
        />
      </CodeContainer>
      <CopyButton top="4" code={rawCode} />
    </Box>
  );
}
